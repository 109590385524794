import { Button, ScreenSize } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import styled from 'styled-components';

import { Media } from 'src/media';
import { LANDING_ID_MAX_WIDTH } from 'src/modules/Landing/LandingPageIndonesia/constants';

export const BannerBackground = styled.div`
  background: linear-gradient(180deg, #071c3f 0%, #10335d 100%);
`;

export const BannerContent = styled.div`
  display: flex;
  max-width: ${LANDING_ID_MAX_WIDTH}px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;

  @media (max-width: ${ScreenSize.desktopS}px) {
    padding: 48px;
  }
`;

export const Title = styled(Typography).attrs({
  variant: 'headline2',
  as: 'h2',
})<{ centered: boolean }>`
  color: white;
  font-weight: bold;
  font-size: 28px;
  text-align: ${(props) => (props.centered ? 'center' : 'start')};

  @media (max-width: ${ScreenSize.desktopS}px) {
    text-align: center;
  }
`;

export const SubTitle = styled(Typography).attrs({
  variant: 'body1',
})<{ centered: boolean }>`
  color: white;
  margin-top: 16px;
  text-align: ${(props) => (props.centered ? 'center' : 'start')};

  @media (max-width: ${ScreenSize.desktopS}px) {
    text-align: center;
  }
`;

export const TitleMobile = styled(Typography).attrs({
  variant: 'headline2',
  as: 'h2',
})`
  color: white;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
`;

export const SubTitleMobile = styled(Typography).attrs({
  variant: 'body1',
})`
  color: white;
  margin-top: 16px;
  text-align: center;
`;

export const DesktopPhoneRowContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  max-width: ${ScreenSize.desktopS}px;

  @media (max-width: ${ScreenSize.desktopS}px) {
    max-width: ${ScreenSize.tablet}px;
  }
`;

export const GlintsDesktopPhoneWrapper = styled.div`
  width: 280px;
  aspect-ratio: 598 / 1063;

  @media (max-width: ${ScreenSize.desktopS}px) {
    width: 215px;
  }

  @media (max-width: ${ScreenSize.tablet}px) {
    width: 170px;
  }
`;

export const GlintsMobilePhoneWrapper = styled.div`
  width: 260px;
  aspect-ratio: 598 / 1063;
  margin-bottom: 16px;
`;

export const DesktopBottomRowContent = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 48px;
  display: flex;
  align-items: center;
  gap: 65px;
  max-width: ${ScreenSize.desktopS}px;

  @media (max-width: ${ScreenSize.desktopS}px) {
    flex-direction: column;
    gap: 40px;
    max-width: ${ScreenSize.tablet}px;
  }
`;

export const GlintsScanToDownloadWrapper = styled.div`
  width: 210px;
  margin-top: 16px;
  aspect-ratio: 523 / 130;
`;

export const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  margin: auto;
  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  width: 152px;
  height: 152px;
  aspect-ratio: 1;
  border: 4px solid transparent;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(62.43deg, #13d6f6 11.73%, #0187e4 64.07%) border-box;
  box-shadow: 6px 6px 0 0 rgba(255, 242, 64, 1);
`;

export const MobileItemWrapper = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const MediaStyle = styled(Media)`
  height: 100%;
`;

export const MobileButton = styled(Button).attrs({
  type: 'button',
  variant: 'yellow',
})`
  width: 320px;
  margin-top: 24px;
  span {
    text-transform: none;
  }
`;
