import 'src/common/builder';
import 'src/modules/LandingV2';

import React, { useEffect } from 'react';
import builder, { BuilderComponent } from '@builder.io/react';
import { keyBy } from 'lodash';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { NextPageContext } from 'next/types';
import { connect } from 'react-redux';

import {
  setInverted,
  setProtected,
  unsetInverted,
  unsetProtected,
} from 'src/actions/app';
import { CountryCodes } from 'src/common/enums';
import { useCountry } from 'src/common/hooks/useConfig';
import isServer from 'src/common/isServer';
import GlintsContainer from 'src/components/GlintsContainer';
import { InView } from 'src/components/InView';
import { MetadataRenderer } from 'src/components/MetaDescription/MetadataRenderer';
import { State } from 'src/global/store';
import { LandingPageID } from 'src/modules/Landing/LandingPageIndonesia/LandingPageID';
import {
  tryGetJobCategoriesByIds,
  useGetHierarchicalJobCategoriesByIds,
} from 'src/modules/Opportunity/hooks/useGetHierarchicalJobCategoriesByIds';
import { JobCategory } from 'src/modules/Profile/graphql/jobCategoriesAndTitles';
import { MeValue } from 'src/reducers/user/me';
import { getUser } from 'src/selectors/user';

import { fetchLandingCounterNumbers } from '../Actions';
import { CareerGridCategoryPositions } from '../Components/constants';
import { GoogleLogoSchema } from '../Components/GoogleLogoSchema';
import { SectionOmniSearch } from '../Components/SectionOmniSearch';
import { LandingCounterNumbers } from '../interfaces';
import { getLandingCounterNumbers, getOpportunitiesCount } from '../Selector';
import {
  PressFeatures,
  Testimonials,
  ValueProposition,
} from './LandingPsychFlat.sc';

const CareersGrid = dynamic(
  () =>
    import(
      /* webpackChunkName: "LandingComponentsCareersGrid" */ '../Components/CareersGrid'
    )
);
const Footer = dynamic(() => import('src/components/Footer/FooterPsychFlat'));

const jobCategoriesIds = CareerGridCategoryPositions.map(
  ({ hierarchicalJobCategoryId }) => hierarchicalJobCategoryId
);

const LandingPsychFlat = (props: Props) => {
  const jobCategoriesResponse =
    useGetHierarchicalJobCategoriesByIds(jobCategoriesIds);

  const country: CountryCodes = useCountry();
  const canShowCareersGrid = country !== CountryCodes.TW;
  const jobCategories =
    props.jobCategories ?? jobCategoriesResponse?.jobCategories;
  const isID = country === CountryCodes.ID;
  const isNotID = !isID;

  const isUserLoggedIn = Boolean(props.me?.id);

  useEffect(() => {
    setInverted();
    unsetProtected();
    return () => {
      unsetInverted();
      setProtected();
    };
  }, [country, isID]);

  const LandingHead = () => (
    <Head>
      <link rel="alternate" hrefLang="x-default" href="https://glints.com" />
      <link rel="alternate" hrefLang="id-id" href="https://glints.com/id" />
      <link rel="alternate" hrefLang="en-id" href="https://glints.com/id/en" />
      <link rel="alternate" hrefLang="en-sg" href="https://glints.com/sg" />
      <link rel="alternate" hrefLang="en-my" href="https://glints.com/my" />
      <link rel="alternate" hrefLang="vi-vn" href="https://glints.com/vn" />
      <link rel="alternate" hrefLang="en-vn" href="https://glints.com/vn/en" />
    </Head>
  );

  if (props.content) {
    return (
      <>
        <MetadataRenderer isLandingPage={true} />
        <LandingHead />
        <BuilderComponent content={props.content} model="page" />
      </>
    );
  }

  return (
    <div className="landing-component">
      <MetadataRenderer isLandingPage={true} />
      <LandingHead />
      <GoogleLogoSchema />

      {isNotID && (
        <React.Fragment>
          <SectionOmniSearch />
          <GlintsContainer>
            {canShowCareersGrid && jobCategories && (
              <InView>
                <CareersGrid
                  landingPageCategoriesById={keyBy(jobCategories, 'id')}
                />
              </InView>
            )}
            <InView>
              <ValueProposition />
            </InView>
            <InView>
              <PressFeatures />
            </InView>
            <InView>
              <Testimonials />
            </InView>
          </GlintsContainer>
        </React.Fragment>
      )}
      {isID && <LandingPageID isUserLoggedIn={isUserLoggedIn} />}
      <InView>
        <Footer />
      </InView>
    </div>
  );
};

LandingPsychFlat.getInitialProps = async (context: NextPageContext) => {
  const { reduxStore, apolloClient } = context;
  const dispatch = reduxStore.dispatch;

  const pagePath: string | undefined = isServer
    ? context.req?.url
    : window.location.pathname;

  const currentLocale = isServer
    ? context.req?.dstContext.clientConfig.LANG
    : window.__NEXT_DATA__.props.dstContext.clientConfig.LANG;

  const isNoCountry =
    !pagePath ||
    (!pagePath.includes('/id') &&
      !pagePath.includes('/sg') &&
      !pagePath.includes('/tw') &&
      !pagePath.includes('/vn'));

  if (isNoCountry) {
    const { publicRuntimeConfig } = getConfig();
    builder.init(publicRuntimeConfig.BUILDER_API_KEY);
    const content = await builder
      .get('page', {
        url: '/homepage-development',
        userAttributes: { locale: currentLocale },
        options: {
          data: {
            locale: currentLocale,
          },
        },
      })
      .promise();

    return {
      content,
    };
  }

  dispatch(fetchLandingCounterNumbers());

  if (isServer) {
    const { jobCategories, error } = await tryGetJobCategoriesByIds({
      apolloClient: apolloClient,
      ids: jobCategoriesIds,
    });

    if (error) {
      console.error('Error fetching job categories', error);
    }

    return {
      landingPageCategories: jobCategories ?? [],
    };
  }

  return {};
};

interface Props
  extends StateProps,
    DispatchProps,
    BuilderPageProps,
    InitialPageProps {}

interface StateProps {
  landingCounterNumbers: LandingCounterNumbers;
  me: MeValue;
}

interface InitialPageProps {
  jobCategories: JobCategory;
}

interface DispatchProps {
  fetchLandingCounterNumbers: () => void;
  setInverted: () => void;
  setProtected: () => void;
  unsetInverted: () => void;
  unsetProtected: () => void;
}

interface BuilderPageProps {
  content: any;
}

const mapStateToProps = (state: State) => ({
  landingCounterNumbers: getLandingCounterNumbers(state),
  opportunitiesCount: getOpportunitiesCount(state),
  me: getUser(state),
});

const mapDispatchToProps = {
  unsetInverted,
  setProtected,
  setInverted,
  unsetProtected,
  fetchLandingCounterNumbers,
};

export default connect<StateProps, DispatchProps, Record<string, unknown>>(
  mapStateToProps,
  mapDispatchToProps
)(LandingPsychFlat);
