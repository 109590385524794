import { ScreenSize } from 'glints-aries';
import styled from 'styled-components';

import GlintsPicture from 'src/components/GlintsPicture';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media (max-width: ${ScreenSize.desktopS}px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Section = styled.div`
  display: block;
`;

export const CustomLink = styled.a`
  width: 100px;

  @media (max-width: ${ScreenSize.desktopS}px) {
    width: 200px;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    width: 120px;
  }
`;

export const Image = styled(GlintsPicture)`
  align-self: center;
  object-fit: contain;
  width: 100%;

  @media (max-width: ${ScreenSize.desktopS}px) {
    zoom: 1.2;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-self: center;
  align-items: center;
  cursor: pointer;
  aspect-ratio: 1;
  width: 100%;

  &:hover {
    border-radius: 4px;
    box-shadow:
      rgba(0, 0, 0, 0.2) 0 0 0 0,
      rgba(45, 45, 45, 0.16) 0 2px 16px;
  }

  @media (max-width: ${ScreenSize.desktopS}px) {
    width: 70%;
  }
`;

export const MoreText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 1.25px;
  align-self: center;
  justify-self: center;
  color: #017eb7;
  cursor: pointer;

  @media screen and (max-width: 640px) {
    grid-column: 1 / -1;
  }
  @media screen and (min-width: 640px) {
    letter-spacing: 0.25px;
  }
  @media screen and (min-width: 991px) {
    letter-spacing: 1.25px;
  }
`;
