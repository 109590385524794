import React from 'react';
import { FormattedMessage } from 'react-intl';

import { AssetType } from 'src/components/GlintsPicture/interfaces';
import * as LandingS from 'src/modules/Landing/LandingPageIndonesia/LandingPageID.sc';

import {
  localeInteractiveMoreWithDots,
  localeTextTrustedCompaniesHiring,
} from '../../LandingPageLocaleKeys';
import * as S from './ActiveEmployers.sc';
import { CustomLink } from './ActiveEmployers.sc';

const moreLink = 'https://glints.com/id/en/companies?countries=ID';

const logos: {
  image: string;
  alt: string;
  link: string;
}[] = [
  {
    image: 'megaCentralFinance.webp',
    alt: 'PT Mega Central Finance (MCF)',
    link: 'https://glints.com/id/en/companies/pt-mega-central-finance-mcf/12cdf5bb-6212-4239-ab9d-9d74a07b546b',
  },
  {
    image: 'oppo.png',
    alt: 'PT. World Innovative Telecommunication',
    link: 'https://glints.com/id/en/companies/pt-world-innovative-telecommunication/26c8c1fc-2db3-488e-ae00-56573b01e5d3',
  },
  {
    image: 'restoranKasAmerica.webp',
    alt: 'A&W Restaurants Indonesia',
    link: 'https://glints.com/id/en/companies/a-and-w-restaurants-indonesia/6bf50f9b-c77a-4513-a007-e85071a9f1aa',
  },
  {
    image: 'ruangGuru.webp',
    alt: 'Ruangguru',
    link: 'https://glints.com/id/en/companies/ruangguru/ec6f34b9-78c7-4a27-9a19-11ab296e2227',
  },
];

const ActiveEmployers = () => {
  return (
    <S.Section>
      <LandingS.SectionTitle>
        <FormattedMessage {...localeTextTrustedCompaniesHiring} />
      </LandingS.SectionTitle>
      <S.Wrapper>
        {logos.map((logo, idx) => (
          <CustomLink
            key={idx}
            href={logo.link}
            target="_blank"
            rel="noreferrer"
          >
            <S.ImageWrapper>
              <S.Image
                key={idx}
                name={`trustedCompanies/${logo.image}`}
                assetType={AssetType.dstHomePage}
                breakpoints={[120, 240]}
                alt={logo.alt}
              />
            </S.ImageWrapper>
          </CustomLink>
        ))}
        <S.MoreText>
          <S.ImageWrapper>
            <CustomLink href={moreLink} target="_blank" rel="noreferrer">
              <FormattedMessage {...localeInteractiveMoreWithDots} />
            </CustomLink>
          </S.ImageWrapper>
        </S.MoreText>
      </S.Wrapper>
    </S.Section>
  );
};

export default ActiveEmployers;
