import React from 'react';
import { Icon, TextInput } from 'glints-aries/lib/@next';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { localeHomepageSearchSectionKeywordSearchPlaceholder } from 'src/modules/Landing/LandingPageLocaleKeys';

const KeywordSearchField = () => {
  const intl = useIntl();
  const { setValue } = useFormContext();

  return (
    <TextInput
      placeholder={intl.formatMessage(
        localeHomepageSearchSectionKeywordSearchPlaceholder
      )}
      prefix={<Icon name="ri-search" />}
      onChange={(value) => setValue('keyword', value)}
    />
  );
};

export default KeywordSearchField;
