import React from 'react';
import { PrimaryButton } from 'glints-aries/lib/@next';
import { FormattedMessage } from 'react-intl';

import { localeHomepageSearchSectionSearchButton } from 'src/modules/Landing/LandingPageLocaleKeys';

const SearchButton: React.FC = () => {
  return (
    <PrimaryButton aria-label="Search button" type="submit">
      <FormattedMessage {...localeHomepageSearchSectionSearchButton} />
    </PrimaryButton>
  );
};

export default SearchButton;
