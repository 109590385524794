import { ScreenSize } from 'glints-aries';
import { Link, Typography } from 'glints-aries/lib/@next';
import styled from 'styled-components';

import { Media } from 'src/media';
import { LANDING_ID_MAX_WIDTH } from 'src/modules/Landing/LandingPageIndonesia/constants';

export const BannerBackground = styled.div`
  background: white;
`;

export const BannerContent = styled.div`
  max-width: ${LANDING_ID_MAX_WIDTH}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
`;

export const Title = styled(Typography).attrs({
  variant: 'headline2',
  as: 'h2',
})`
  color: black;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
`;

export const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 24px auto 0 auto;

  @media (max-width: ${ScreenSize.mobileL}px) {
    margin-top: 12px;
  }
`;

export const GeoMapWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 4fr);
  grid-template-rows: auto;
  gap: 20px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 6fr;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const StyledLink = styled(Link)`
  justify-content: center;
  text-align: center;
  text-decoration: underline;
`;

export const MediaStyle = styled(Media)`
  width: 95%;
  aspect-ratio: 1000 / 362;

  @media (max-width: ${ScreenSize.mobileL}px) {
    width: 100%;
    aspect-ratio: 632 / 362;
  }
`;
