import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { defaultCityProvinceAll } from 'src/common/enums';
import { useCountryData } from 'src/common/hooks/useCountryData';
import { getUtmParams } from 'src/common/links';
import { localeTextHomepageTitle } from 'src/modules/Landing/LandingPageLocaleKeys';
import { generateSearchQuery } from 'src/modules/Opportunities/pages/Explore/Components/SearchSection/generateSearchQuery';
import { UpdatePastSearchesStateContext } from 'src/modules/Opportunities/pages/Explore/Components/SearchSuggestions/Context';
import { SearchRouterQueryKey } from 'src/modules/Opportunities/pages/Explore/types';

import KeywordSearchField from './KeywordSearchField';
import LocationSearchField from './LocationSearchField';
import SearchButton from './SearchButton';
import * as S from './SearchSection.sc';

const SearchSection = () => {
  const { countryCode } = useCountryData();
  const methods = useForm({
    defaultValues: {
      location: { country: countryCode, locationName: defaultCityProvinceAll },
    },
  });
  const router = useRouter();
  const marketingURLParams = getUtmParams(router.query);
  const { updatePastJobSearches } = useContext(UpdatePastSearchesStateContext);

  const onSubmit = async (data: {
    keyword?: string;
    location?: {
      country?: string;
      searchCity?: string;
      locationId?: string;
      locationName?: string;
    };
  }): Promise<void> => {
    const routerQuery = generateSearchQuery(
      data.keyword,
      {
        [SearchRouterQueryKey.Country]: data.location?.country,
        [SearchRouterQueryKey.City]: data.location?.searchCity,
        [SearchRouterQueryKey.LocationId]: data.location?.locationId,
        [SearchRouterQueryKey.Location]: data.location?.locationName,
      },
      updatePastJobSearches,
      marketingURLParams
    );
    await router.push(routerQuery);
  };

  return (
    <FormProvider {...methods}>
      <S.Form onSubmit={methods.handleSubmit(onSubmit)}>
        <S.Title>
          <FormattedMessage {...localeTextHomepageTitle} />
        </S.Title>
        <S.Container>
          <S.FieldWrapper>
            <KeywordSearchField />
          </S.FieldWrapper>
          <S.LocationFieldWrapper>
            <LocationSearchField countryCode={countryCode} />
          </S.LocationFieldWrapper>
          <SearchButton />
        </S.Container>
      </S.Form>
    </FormProvider>
  );
};

export default SearchSection;
