import React from 'react';

import { useConfig } from 'src/common/hooks';
import ActiveEmployers from 'src/modules/Landing/LandingPageIndonesia/Components/ActiveEmployers';
import BlueBanner from 'src/modules/Landing/LandingPageIndonesia/Components/BlueBanner';
import MapSection from 'src/modules/Landing/LandingPageIndonesia/Components/MapSection';
import { PopularJobs } from 'src/modules/Landing/LandingPageIndonesia/Components/PopularJobs';
import PopularSearches from 'src/modules/Landing/LandingPageIndonesia/Components/PopularSearches';
import SearchSection from 'src/modules/Landing/LandingPageIndonesia/Components/SearchSection';
import Testimonials from 'src/modules/Landing/LandingPageIndonesia/Components/Testimonials';
import UrgentJobsChip from 'src/modules/Landing/LandingPageIndonesia/Components/UrgentJobsChip';
import YellowBanner from 'src/modules/Landing/LandingPageIndonesia/Components/YellowBanner';
import {
  ColumnSection,
  LandingPageBackground,
  LandingPageContainer,
} from 'src/modules/Landing/LandingPageIndonesia/LandingPageID.sc';

interface Props {
  isUserLoggedIn: boolean;
}

export const LandingPageID: React.FC<React.PropsWithChildren<Props>> = () => {
  const { LANG: language } = useConfig();

  return (
    <LandingPageBackground>
      <LandingPageContainer>
        <SearchSection />
        <PopularJobs />
        <ColumnSection>
          <UrgentJobsChip />
          <ActiveEmployers />
        </ColumnSection>
      </LandingPageContainer>
      <YellowBanner language={language} />
      <BlueBanner language={language} />
      <Testimonials />
      <MapSection />
      <PopularSearches />
    </LandingPageBackground>
  );
};
