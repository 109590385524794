import React, { ReactElement, useState } from 'react';
import { Tabs } from 'glints-aries/lib/@next';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import * as LandingS from 'src/modules/Landing/LandingPageIndonesia/LandingPageID.sc';

import {
  localeHomepagePopularSearchesJobCategory,
  localeHomepagePopularSearchesJobRoles,
  localeHomepagePopularSearchesKeyword,
  localeHomepagePopularSearchesLocation,
  localeTextJobTypeCategory,
  localeTextL3JobCategory,
  localeTextPopularSearchesOnGlints,
} from '../../LandingPageLocaleKeys';
import {
  POPULAR_SEARCH_CATEGORIES,
  POPULAR_SEARCH_KEYWORDS,
  POPULAR_SEARCH_LOCATIONS,
  POPULAR_SEARCH_ROLES,
} from '../constants';
import * as S from './PopularSearches.sc';
import { PopularSearchesContent } from './PopularSearches.sc';

export type Item = {
  textJobs?: MessageDescriptor;
  textOnlineJobs?: MessageDescriptor;
  text?: MessageDescriptor;
  location?: string;
  url: string;
};

const TabContent = ({ items }: { items: Item[] }) => {
  const intl = useIntl();
  if (items.length === 0) return <></>;

  return (
    <S.Wrapper>
      {items.map((item, idx: number) => {
        let text: ReactElement | undefined;
        if (item.textJobs) {
          text = (
            <FormattedMessage
              {...localeTextL3JobCategory}
              values={{
                categoryName: intl.formatMessage(item.textJobs),
              }}
            />
          );
        } else if (item.textOnlineJobs) {
          text = (
            <FormattedMessage
              {...localeTextJobTypeCategory}
              values={{
                jobType: intl.formatMessage(item.textOnlineJobs),
              }}
            />
          );
        } else if (item.location) {
          text = (
            <FormattedMessage
              {...localeTextL3JobCategory}
              values={{
                categoryName: item.location,
              }}
            />
          );
        } else if (item.text) {
          text = <FormattedMessage {...item.text} />;
        }

        if (!text) return <></>;

        return (
          <S.TextBlock key={idx}>
            <a
              href={item.url}
              style={{ color: 'inherit' }}
              target="_blank"
              rel="noreferrer"
            >
              {text}
            </a>
            {idx !== items.length - 1 && <>,</>}
          </S.TextBlock>
        );
      })}
    </S.Wrapper>
  );
};

const PopularSearches = () => {
  const intl = useIntl();
  const [selected, setSelected] = useState(0);

  const tabs = [
    {
      id: 'tab-1',
      content: intl.formatMessage(localeHomepagePopularSearchesJobRoles),
    },
    {
      id: 'tab-2',
      content: intl.formatMessage(localeHomepagePopularSearchesJobCategory),
    },
    {
      id: 'tab-3',
      content: intl.formatMessage(localeHomepagePopularSearchesLocation),
    },
    {
      id: 'tab-4',
      content: intl.formatMessage(localeHomepagePopularSearchesKeyword),
    },
  ];

  const tabContent = [
    <TabContent key={0} items={POPULAR_SEARCH_ROLES} />,
    <TabContent key={1} items={POPULAR_SEARCH_CATEGORIES} />,
    <TabContent key={2} items={POPULAR_SEARCH_LOCATIONS} />,
    <TabContent key={3} items={POPULAR_SEARCH_KEYWORDS} />,
  ];

  return (
    <S.PopularSearchesWrapper>
      <PopularSearchesContent>
        <LandingS.SectionTitle>
          <FormattedMessage {...localeTextPopularSearchesOnGlints} />
        </LandingS.SectionTitle>
      </PopularSearchesContent>
      <S.PopularSearchTabWrapper>
        <Tabs
          tabs={tabs}
          disabledScrollToTab={true}
          onSelected={(i) => setSelected(i)}
        >
          {tabContent[selected]}
        </Tabs>
      </S.PopularSearchTabWrapper>
    </S.PopularSearchesWrapper>
  );
};

export default PopularSearches;
