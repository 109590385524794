import { gql } from '@apollo/client';

// The existing HierarchicalJobCategory is very hard to use. There are
// several types that mean exactly the same: HierarchyJobCategoryBase, HierarchicalJobCategoryL1
// HierarchicalJobCategoryL2, HierarchicalJobCategoryL3, HierarchicalJobCategory.
// Eventually every hierarchical job category across the app has to be consistent
export type JobCategory = {
  id: string;
  name: string;
  level: 1 | 2 | 3;
  slug?: string;
  parents?: JobCategory[];
};

/** this is a work-around for recursive type complaining in
 * react-hook-form field array.
 * context: https://github.com/react-hook-form/react-hook-form/issues/4055#issuecomment-950145092
 */
type HierarchicalJobCategoryFamilyArray<T> = {
  [key: number]: Omit<T, ''>;
};

export type HierarchyJobCategoryBase = {
  HierarchicalJobCategoryId: string;
  name: string;
  level: number;
  slug?: string | null;
};

export type HierarchyJobRolePreferenceCategory = {
  HierarchicalJobCategoryId: string;
  name: string;
  level: number;
  parents: HierarchyJobCategoryBase[];
};

export type HierarchicalJobCategoryL1 = HierarchyJobCategoryBase & {
  level: 1;
  children: HierarchicalJobCategoryFamilyArray<HierarchicalJobCategoryL2>;
};

export type HierarchicalJobCategoryL2 = HierarchyJobCategoryBase & {
  parents: HierarchicalJobCategoryFamilyArray<HierarchicalJobCategoryL1>;
  level: 2;
  children: HierarchicalJobCategoryFamilyArray<HierarchicalJobCategoryL3>;
};

export type HierarchicalJobCategoryL3 = HierarchyJobCategoryBase & {
  level: 3;
  parents: HierarchicalJobCategoryFamilyArray<HierarchicalJobCategoryL3>;
};

export type HierarchicalJobCategory =
  | HierarchicalJobCategoryL1
  | HierarchicalJobCategoryL2
  | HierarchicalJobCategoryL3;

export type HierarchyJobRolePreferences = {
  id: string;
  HierarchicalJobCategoryId: string;
  HierarchicalJobCategory: HierarchyJobRolePreferenceCategory;
};

export type JobRolePreferencesQueryData = {
  jobRolePreferences: HierarchyJobRolePreferences[];
};

export type JobRolePreferencesQueryByIdData = {
  hierarchicalJobCategory: HierarchicalJobCategory;
};

export type ValidJobCategoryProperties = keyof Pick<
  HierarchyJobCategoryBase,
  'name' | 'slug'
>;

export interface JobCategorySlugs {
  jobCategorySlug?: string;
  jobSubCategorySlug?: string;
  jobRoleCategorySlug?: string;
}
export interface JobCategoryNames {
  jobCategoryName?: string;
  jobSubCategoryName?: string;
  jobRoleName?: string;
}

export interface GetJobCategoriesResponse {
  hierarchicalJobCategory: JobCategory;
}

export const getHierarchicalJobCategories = gql`
  query HierarchicalJobCategory {
    hierarchicalJobCategories {
      HierarchicalJobCategoryId: id
      name
      level
      children {
        HierarchicalJobCategoryId: id
        name
        level
      }
    }
  }
`;

export const getHierarchicalJobCategoriesWithL3 = gql`
  query HierarchicalJobCategory {
    hierarchicalJobCategories {
      HierarchicalJobCategoryId: id
      name
      level
      children {
        HierarchicalJobCategoryId: id
        name
        level
        children {
          HierarchicalJobCategoryId: id
          name
          level
        }
      }
    }
  }
`;

export const getJobCategoryById = gql`
  query HierarchicalJobCategory($id: String!) {
    hierarchicalJobCategory(id: $id) {
      id
      name
      slug
      level
      parents {
        id
        name
        slug
        level
      }
    }
  }
`;

export const getHierarchicalJobCategoryById = gql`
  query HierarchicalJobCategory($id: String!) {
    hierarchicalJobCategory(id: $id) {
      HierarchicalJobCategoryId: id
      name
      level
      children {
        name
        level
        children {
          HierarchicalJobCategoryId: id
          name
          level
        }
      }
    }
  }
`;

export const getJobRolePreferencesQuery = gql`
  query JobRolePreferenceResolver {
    jobRolePreferences {
      id
      HierarchicalJobCategoryId
      HierarchicalJobCategory {
        HierarchicalJobCategoryId: id
        name
        level
        defaultName
        parents {
          HierarchicalJobCategoryId: id
          level
          name
          defaultName
        }
      }
    }
  }
`;

// This GraphQL query is used for get Hierarchical Job Category including slug
export const getHierarchicalJobCategoryIncludingSlugById = gql`
  query getHierarchicalJobCategoriesByIds($ids: [UUID!]!) {
    getHierarchicalJobCategoriesByIds(ids: $ids) {
      id
      name
      slug
      level
    }
  }
`;
