import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import * as LandingS from 'src/modules/Landing/LandingPageIndonesia/LandingPageID.sc';

import {
  localeJobCategory11,
  localeTextL1CustomerService,
  localeTextL2Administrative,
  localeTextL2DigitalMarketing,
  localeTextL2QualityAssurance,
  localeTextL3ContentCreator,
  localeTextL3DataAnalyst,
  localeTextL3ProductManager,
  localeTextL3SocialMediaSpecialist,
  localeTextL3UiUxDesigner,
  localeTextL3WebDeveloper,
  localeTextSkillContentWriting,
  localeTextSkillGraphicDesign,
  localeTextUrgentlyNeededJobs,
} from '../../LandingPageLocaleKeys';
import * as S from './UrgentJobsChip.sc';

const jobs: {
  jobName: MessageDescriptor;
  link: string;
}[] = [
  {
    jobName: localeTextL3DataAnalyst,
    link: '/id/find-jobs/loker-data-analyst',
  },
  {
    jobName: localeTextL2DigitalMarketing,
    link: '/id/find-jobs/loker-digital-marketing',
  },
  {
    jobName: localeTextL1CustomerService,
    link: '/id/find-jobs/loker-customer-service',
  },
  {
    jobName: localeTextL2Administrative,
    link: '/id/job-category/administrative-human-resources',
  },
  {
    jobName: localeTextSkillGraphicDesign,
    link: '/id/find-jobs/loker-graphic-designer',
  },
  {
    jobName: localeTextSkillContentWriting,
    link: '/id/find-jobs/loker-content-writer',
  },
  {
    jobName: localeTextL2QualityAssurance,
    link: '/id/find-jobs/loker-quality-assurance',
  },
  {
    jobName: localeTextL3WebDeveloper,
    link: '/id/find-jobs/loker-web-developer',
  },
  {
    jobName: localeJobCategory11,
    link: '/id/job-category/administrative-human-resources',
  },
  {
    jobName: localeTextL3UiUxDesigner,
    link: '/id/job-category/design/visual-interactive-design/ui-ux-designer',
  },
  {
    jobName: localeTextL3ContentCreator,
    link: '/id/find-jobs/loker-content-creator',
  },
  {
    jobName: localeTextL3ProductManager,
    link: '/id/job-category/product-management-project-management',
  },
  {
    jobName: localeTextL3SocialMediaSpecialist,
    link: '/id/find-jobs/loker-social-media-specialist',
  },
];

const UrgentJobsChip = () => {
  return (
    <S.Section>
      <LandingS.SectionTitle>
        <FormattedMessage {...localeTextUrgentlyNeededJobs} />
      </LandingS.SectionTitle>
      <S.Wrapper>
        {jobs.map((job, index) => (
          <a
            key={`${job.link}_${index}`}
            href={job.link}
            target="_blank"
            rel="noreferrer"
          >
            <S.StyledTag textColor="#017EB7">
              <FormattedMessage {...job.jobName} />
            </S.StyledTag>
          </a>
        ))}
      </S.Wrapper>
    </S.Section>
  );
};

export default UrgentJobsChip;
