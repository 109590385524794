import { ScreenSize } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import styled from 'styled-components';

import { Media } from 'src/media';
import { LANDING_ID_MAX_WIDTH } from 'src/modules/Landing/LandingPageIndonesia/constants';

export const BannerWrapper = styled.div`
  display: block;
  height: 500px;
  background: linear-gradient(180deg, #f2e634 0%, #fff784 100%);

  @media (max-width: ${ScreenSize.desktopS}px) {
    height: 320px;
  }

  @media (max-width: ${ScreenSize.tablet}px) {
    height: 540px;
  }
`;

export const RowContent = styled.div`
  height: 100%;
  max-width: ${LANDING_ID_MAX_WIDTH}px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;

  @media (max-width: ${ScreenSize.desktopS}px) {
    flex-direction: column;
  }

  @media (max-width: ${ScreenSize.tablet}px) {
    justify-content: start;
    gap: 6px;
  }
`;

export const Title = styled(Typography).attrs({
  variant: 'headline2',
  as: 'h2',
})`
  color: black;
  font-size: 40px;
  font-weight: bolder;

  @media (max-width: ${ScreenSize.desktopS}px) {
    font-size: 28px;
    font-weight: 500;
  }

  @media (max-width: ${ScreenSize.tablet}px) {
    font-size: 24px;
    margin-top: 24px;
    font-weight: bolder;
  }
`;

export const SubTitle = styled(Typography).attrs({
  variant: 'body1',
})`
  color: black;
  font-size: 24px;

  @media (max-width: ${ScreenSize.desktopS}px) {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const JustChatText = styled(Typography).attrs({
  variant: 'body1',
})`
  color: rgba(236, 39, 43, 1);
  font-size: 24px;
  margin: 8px 0 24px 0;
  @media (max-width: ${ScreenSize.desktopS}px) {
    font-size: 14px;
    font-weight: 400;
  }

  @media (max-width: ${ScreenSize.tablet}px) {
    font-size: 18px;
    margin: 0;
  }
`;

export const GlintsDesktopPhoneWrapper = styled.div`
  height: 500px;
  aspect-ratio: 1400 / 1378;
`;

export const GlintsMobilePhoneWrapper = styled.div`
  width: 250px;
  height: 100%;
  align-content: end;
  aspect-ratio: 958 / 1198;
  margin-bottom: -5px;
`;

export const GlintsScanToDownloadWrapper = styled.div`
  width: 210px;
  aspect-ratio: 526 / 130;
`;

export const QRCodeSection = styled.div`
  gap: 24px;
  display: flex;
  align-items: center;
`;

export const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: auto;
  box-sizing: border-box;
  width: 152px;
  height: 152px;
  aspect-ratio: 1;
  border: 4px solid transparent;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(62.43deg, #13d6f6 11.73%, #0187e4 64.07%) border-box;
  box-shadow: 6px 6px 0 0 rgba(1, 126, 183, 1);
`;

export const MediaStyle = styled(Media)`
  height: 100%;
`;
