import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import * as LandingS from 'src/modules/Landing/LandingPageIndonesia/LandingPageID.sc';

import { localeTextPopularJobCategories } from '../../LandingPageLocaleKeys';
import { POPULAR_JOB_CATEGORIES } from '../constants';
import * as S from './PopularJobs.sc';

export const PopularJobs = () => {
  const intl = useIntl();
  return (
    <Fragment>
      <LandingS.SectionTitle>
        <FormattedMessage {...localeTextPopularJobCategories} />
      </LandingS.SectionTitle>
      <S.Wrapper>
        {POPULAR_JOB_CATEGORIES.map((job, index) => (
          <a
            key={`${job.link}_${index}`}
            href={job.link}
            className="job-item"
            rel="noreferrer"
            target="_blank"
          >
            <S.JobCard
              isHighlighted={job.isHighlighted ?? false}
              color={job.highlightColor}
            >
              {job.isHighlighted && (
                <S.JobHighlightIcon
                  src={job.highlightIcon}
                  alt={`${intl.formatMessage(job.text)} Icon`}
                />
              )}
              <S.JobText
                isHighlighted={job.isHighlighted ?? false}
                highlightColor={job.highlightColor}
                variant="caption"
              >
                <FormattedMessage {...job.text} />
              </S.JobText>
            </S.JobCard>
          </a>
        ))}
      </S.Wrapper>
    </Fragment>
  );
};
