type EntityLocale = {
  id: string;
  defaultMessage: string;
};

/**
 * ActiveEmployers.tsx
 */
export const localeTextTrustedCompaniesHiring: EntityLocale = {
  id: `text-trusted-companies-hiring`,
  defaultMessage: `Trusted companies, hiring`,
};

export const localeInteractiveMoreWithDots: EntityLocale = {
  id: `interactive-more-with-dots`,
  defaultMessage: `More...`,
};

/**
 * BlueBanner.tsx
 */

export const localeTextSearchApplyGetHired: EntityLocale = {
  id: `text-search-apply-get-hired`,
  defaultMessage: `Search. Apply. Get hired. Do it all on the app!`,
};

export const localeTextUnlockFullPotential: EntityLocale = {
  id: `text-unlock-full-potential`,
  defaultMessage: `Unlock the full potential of your job search with personalized recommendations, application reminders, and seamless application tracking—all at your fingertips.`,
};

export const localeTextScanTheQrCodeToDownload: EntityLocale = {
  id: `text-scan-the-qr-code-to-download`,
  defaultMessage: `Scan the QR code to download`,
};

export const localeTextDownloadTheGlintsAppNow: EntityLocale = {
  id: `text-download-the-glints-app-now`,
  defaultMessage: `Download the Glints App Now`,
};

export const localeTextDiscoverYourDreamJob: EntityLocale = {
  id: `text-discover-your-dream-job`,
  defaultMessage: `Discover your dream job and grow your career with more features on the app`,
};

export const localeTextLetJobsComeYou: EntityLocale = {
  id: `text-let-jobs-come-you`,
  defaultMessage: `First, let jobs that you want come to you`,
};

export const localeTextGetJobRecommendationsPreferences: EntityLocale = {
  id: `text-get-job-recommendations-preferences`,
  defaultMessage: `Get job recommendations based on your preferences, updated daily on the app`,
};

export const localeInteractiveViewRecommendationsOnApp: EntityLocale = {
  id: `interactive-view-recommendations-on-app`,
  defaultMessage: `View Recommendations on App`,
};

export const localetextChatWithHrdAnytimeAnywhere: EntityLocale = {
  id: `text-chat-with-hrd-anytime-anywhere`,
  defaultMessage: `Also, chat with HRD anytime, anywhere`,
};

export const localeTextNeverMissHdrMessage: EntityLocale = {
  id: `text-never-miss-hdr-message`,
  defaultMessage: `Never miss any message from HRD, track it any time, real time, on the app.`,
};

export const localeInteractiveChatWithHrdOnApp: EntityLocale = {
  id: `interactive-chat-with-hrd-on-app`,
  defaultMessage: `Chat with HRD on the Glints App`,
};

export const localeTextRemindersApplySavedJobs: EntityLocale = {
  id: `text-reminders-apply-saved-jobs`,
  defaultMessage: `Then, get reminders to apply for saved jobs`,
};

export const localeTextAfraidForgettingApplyJobs: EntityLocale = {
  id: `text-afraid-forgetting-apply-jobs`,
  defaultMessage: `Afraid of forgetting to apply for jobs that you've saved? Get reminders on the app`,
};

export const localeInteractiveApplyJobsOnApp: EntityLocale = {
  id: `interactive-apply-jobs-on-app`,
  defaultMessage: `Apply for Jobs on App`,
};

export const localeInteractiveGoToApp: EntityLocale = {
  id: `interactive-go-to-app`,
  defaultMessage: `Go to Glints App Now`,
};

/**
 * MapSection.tsx
 */
export const localeTextBigCitiesLocalArea: EntityLocale = {
  id: `text-big-cities-local-area`,
  defaultMessage: `Find opportunities in big cities or your local area`,
};

/**
 * SearchSection.tsx
 */
export const localeTextHomepageTitle: EntityLocale = {
  id: `text-homepage-title`,
  defaultMessage: `Find 30,000+ jobs in Indonesia`,
};

/**
 * SearchButton.tsx
 */
export const localeHomepageSearchSectionSearchButton: EntityLocale = {
  id: `homepage.search_section.search_button`,
  defaultMessage: `Search`,
};

/**
 * KeywordSearchField.tsx
 */
export const localeHomepageSearchSectionKeywordSearchPlaceholder: EntityLocale =
  {
    id: `homepage.search_section.keyword_search_placeholder`,
    defaultMessage: `Search by Title, Skill or Company`,
  };

/**
 * LocationSearchField.tsx
 */
export const localeLocationAll: EntityLocale = {
  id: `location.all`,
  defaultMessage: `All Cities/Provinces`,
};

/**
 * UrgenJobsChip.tsx
 */
export const localeTextL3DataAnalyst: EntityLocale = {
  id: `text-l3-data-analyst`,
  defaultMessage: `Data Analyst`,
};

export const localeTextL2DigitalMarketing: EntityLocale = {
  id: `text-l2-digital-marketing`,
  defaultMessage: `Digital Marketing`,
};

export const localeTextL1CustomerService: EntityLocale = {
  id: `text-l1-customer-service`,
  defaultMessage: `Customer Service`,
};

export const localeTextL2Administrative: EntityLocale = {
  id: `text-l2-administrative`,
  defaultMessage: `Administrative`,
};

export const localeTextSkillGraphicDesign: EntityLocale = {
  id: `text-skill-graphic-design`,
  defaultMessage: `Graphic Design`,
};

export const localeTextSkillContentWriting: EntityLocale = {
  id: `text-skill-content-writing`,
  defaultMessage: `Content Writing`,
};

export const localeTextL2QualityAssurance: EntityLocale = {
  id: `text-l2-quality-assurance`,
  defaultMessage: `Quality Assurance`,
};

export const localeTextL3WebDeveloper: EntityLocale = {
  id: `text-l3-web-developer`,
  defaultMessage: `Web Developer`,
};

export const localeJobCategory11: EntityLocale = {
  id: `jobCategory.11`,
  defaultMessage: `Human Resource`,
};

export const localeTextL3UiUxDesigner: EntityLocale = {
  id: `text-l3-ui/ux-designer`,
  defaultMessage: `UI/UX Designer`,
};

export const localeTextL3ContentCreator: EntityLocale = {
  id: `text-l3-content-creator`,
  defaultMessage: `Content Creator`,
};

export const localeTextL3ProductManager: EntityLocale = {
  id: `text-l3-product-manager`,
  defaultMessage: `Product Manager`,
};

export const localeTextL3SocialMediaSpecialist: EntityLocale = {
  id: `text-l3-social-media-specialist`,
  defaultMessage: `Social Media Specialist`,
};

export const localeTextUrgentlyNeededJobs: EntityLocale = {
  id: `text-urgently-needed-jobs`,
  defaultMessage: `Urgently needed jobs`,
};

/**
 * UrgenJobsChip.tsx
 */
export const localeTextGlintsIsFastestPlatform: EntityLocale = {
  id: `text-glints-is-fastest-platform`,
  defaultMessage: `"Glints is the easiest & fastest platform to get a job. I can successfully switch career into the industry that I'm passionate about & get a job in just 4 days."`,
};

export const localeTextSkillContentStrategy: EntityLocale = {
  id: `text-skill-content-strategy`,
  defaultMessage: `Content Strategy`,
};

export const localeTextTestimonialOne: EntityLocale = {
  id: 'text-testimonial-one',
  defaultMessage: 'Your testimonial one message here',
};

export const localeTextJobRoleSeniorAccountExecutive: EntityLocale = {
  id: 'text-job-role-senior-account-executive',
  defaultMessage: 'Senior Account Executive',
};

export const localeTextTestimonialTwo: EntityLocale = {
  id: 'text-testimonial-two',
  defaultMessage: 'Your testimonial two message here',
};

export const localeTextSkillMarketingCommunications: EntityLocale = {
  id: 'text-skill-marketing-communications',
  defaultMessage: 'Marketing Communications',
};

export const localeTextMillionPeopleGetHired: EntityLocale = {
  id: 'text-million-people-get-hired',
  defaultMessage: 'See how millions of people get hired',
};

export const localeTextSeeHowLandJobs: EntityLocale = {
  id: 'text-see-how-land-jobs',
  defaultMessage: 'See how they land their dream jobs through Glints',
};

export const localeTextYears: EntityLocale = {
  id: 'text-years',
  defaultMessage: '{age} years old',
};

/**
 * PopularJobs.tsx
 */
export const localeTextL1AdministrativeHumanResources: EntityLocale = {
  id: 'text-l1-administrative-human-resources',
  defaultMessage: 'Administrative & Human Resources',
};

export const localeTextL1Marketing: EntityLocale = {
  id: 'text-l1-marketing',
  defaultMessage: 'Marketing',
};

export const localeTextL1Operations: EntityLocale = {
  id: 'text-l1-operations',
  defaultMessage: 'Operations',
};

export const localeActivelyHiring: EntityLocale = {
  id: `actively.hiring`,
  defaultMessage: `Actively Hiring`,
};

export const localeTextL1SupplyChainLogistics: EntityLocale = {
  id: 'text-l1-supply-chain-logistics',
  defaultMessage: 'Supply Chain & Logistics',
};

export const localeTextL1BusinessDevelopmentSales: EntityLocale = {
  id: 'text-l1-business-development-sales',
  defaultMessage: 'Business Development & Sales',
};

export const localeTextL1AccountingFinance: EntityLocale = {
  id: 'text-l1-accounting-finance',
  defaultMessage: 'Accounting & Finance',
};

export const localeInteractiveRemoteJobs: EntityLocale = {
  id: 'interactive-remote-jobs',
  defaultMessage: 'Remote Jobs',
};

export const localeTextL1Design: EntityLocale = {
  id: 'text-l1-design',
  defaultMessage: 'Design',
};

export const localeTextL1MediaCommunications: EntityLocale = {
  id: 'text-l1-media-communications',
  defaultMessage: 'Media & Communications',
};

export const localeTextL1ComputerInformationTechnology: EntityLocale = {
  id: 'text-l1-computer-information-technology',
  defaultMessage: 'Computer & Information Technology',
};

export const localeInteractiveFreshGraduates: EntityLocale = {
  id: `interactive-fresh-graduates`,
  defaultMessage: `Fresh Graduates`,
};

export const localeTextPopularJobCategories: EntityLocale = {
  id: 'text-popular-job-categories',
  defaultMessage: 'Popular Job Categories',
};

/**
 * PopularSearches.tsx
 */
export const localeProjectBased: EntityLocale = {
  id: 'project-based',
  defaultMessage: 'Project Based',
};

export const localeTextJobRoleWfh: EntityLocale = {
  id: 'text-job-role-wfh',
  defaultMessage: 'Work From Home',
};

export const localeTextJobRoleBumn: EntityLocale = {
  id: 'text-job-role-bumn',
  defaultMessage: 'BUMN',
};

export const localeTextJobRolesupirPribadi: EntityLocale = {
  id: 'text-job-role-supir-pribadi',
  defaultMessage: 'Supir Pribadi',
};

export const localeTextL3VirtualAssistant: EntityLocale = {
  id: 'text-l3-virtual-assistant',
  defaultMessage: 'Virtual Assistant',
};

export const localeTextL3CopywriterCopyeditor: EntityLocale = {
  id: 'text-l3-copywriter-copyeditor',
  defaultMessage: 'Copywriter',
};

export const localeTextJobRoleGuru: EntityLocale = {
  id: 'text-job-role-guru',
  defaultMessage: 'Guru',
};

export const localeTextL3Illustrator: EntityLocale = {
  id: 'text-l3-illustrator',
  defaultMessage: 'Illustrator',
};

export const localeTextJobRoleWeddingOrganizer: EntityLocale = {
  id: 'text-job-role-wedding-organizer',
  defaultMessage: 'Wedding Organizer',
};

export const localeTextL3DataEntry: EntityLocale = {
  id: 'text-l3-data-entry',
  defaultMessage: 'Data Entry',
};

export const localeTextJobRoleLulusanSma: EntityLocale = {
  id: 'text-job-role-lulusan-sma',
  defaultMessage: 'Lulusan SMA',
};

export const localeTextJobRoleFreelanceWriter: EntityLocale = {
  id: 'text-job-role-freelance-writer',
  defaultMessage: 'Freelance Writer',
};

export const localeTextL3Model: EntityLocale = {
  id: 'text-l3-model',
  defaultMessage: 'Model',
};

export const localeTextL2HumanResources: EntityLocale = {
  id: 'text-l2-human-resources',
  defaultMessage: 'Human Resources',
};

export const localeJobCategory49: EntityLocale = {
  id: 'job-category-49',
  defaultMessage: 'Hotel, Restaurant & Travel',
};

export const localeTextL2Marketing: EntityLocale = {
  id: 'text-l2-marketing',
  defaultMessage: 'Marketing',
};

export const localeJobCategory53: EntityLocale = {
  id: 'job-category-53',
  defaultMessage: 'Legal, Consulting & Translation',
};

export const localeTextL1Finance: EntityLocale = {
  id: 'text-l1-finance',
  defaultMessage: 'Finance',
};

export const localeJobCategory51: EntityLocale = {
  id: 'job-category-51',
  defaultMessage: 'Legal Services',
};

export const localeTextL1Healthcare: EntityLocale = {
  id: 'text-l1-healthcare',
  defaultMessage: 'Healthcare',
};

export const localeTextL2Consulting: EntityLocale = {
  id: 'text-l2-consulting',
  defaultMessage: 'Consulting',
};

export const localeJobCategory1: EntityLocale = {
  id: 'job-category-1',
  defaultMessage: 'Information Technology',
};

export const localeTextL2ProductManagement: EntityLocale = {
  id: 'text-l2-product-management',
  defaultMessage: 'Product Management',
};

export const localeJobCategory2: EntityLocale = {
  id: 'job-category-2',
  defaultMessage: 'Software Development',
};

export const localeJobCategory8: EntityLocale = {
  id: 'job-category-8',
  defaultMessage: 'Web Development',
};

export const localeTextL3Writer: EntityLocale = {
  id: 'text-l3-writer',
  defaultMessage: 'Writer',
};

export const localeTextL3Recruiter: EntityLocale = {
  id: 'text-l3-recruiter',
  defaultMessage: 'Recruiter',
};

export const localeTextL3Accountant: EntityLocale = {
  id: 'text-l3-accountant',
  defaultMessage: 'Accountant',
};

export const localeTextL3SalesManager: EntityLocale = {
  id: 'text-l3-sales-manager',
  defaultMessage: 'Sales Manager',
};

export const localeTextL3SalesExecutive: EntityLocale = {
  id: 'text-l3-sales-executive',
  defaultMessage: 'Sales Executive',
};

export const localeTextL3AccountManager: EntityLocale = {
  id: 'text-l3-account-manager',
  defaultMessage: 'Account Manager',
};

export const localeTextL3FilmVideoEditor: EntityLocale = {
  id: 'text-l3-film-video-editor',
  defaultMessage: 'Film/Video Editor',
};

export const localeTextL3DigitalMarketingManager: EntityLocale = {
  id: 'text-l3-digital-marketing-manager',
  defaultMessage: 'Digital Marketing Manager',
};

export const localeTextL3FrontendDeveloper: EntityLocale = {
  id: 'text-l3-frontend-developer',
  defaultMessage: 'Frontend Developer',
};

export const localeTextL3BackendDeveloper: EntityLocale = {
  id: 'text-l3-backend-developer',
  defaultMessage: 'Backend Developer',
};

export const localeTextL3AndroidDeveloper: EntityLocale = {
  id: 'text-l3-android-developer',
  defaultMessage: 'Android Developer',
};

export const localeTextL3GraphicBrandDesigner: EntityLocale = {
  id: 'text-l3-graphic-brand-designer',
  defaultMessage: 'Graphic/Brand Designer',
};

export const localeTextL3OfficeManager: EntityLocale = {
  id: 'text-l3-office-manager',
  defaultMessage: 'Office Manager',
};

export const localeTextL3JobCategory: EntityLocale = {
  id: 'text-l3-job-category',
  defaultMessage: '{categoryName}',
};

export const localeTextJobTypeCategory: EntityLocale = {
  id: 'text-job-type-category',
  defaultMessage: '{jobType}',
};

export const localeHomepagePopularSearchesJobRoles: EntityLocale = {
  id: `homepage.popular_searches.job_roles`,
  defaultMessage: `Job Roles`,
};

export const localeHomepagePopularSearchesJobCategory: EntityLocale = {
  id: `homepage.popular_searches.job_category`,
  defaultMessage: `Job Category`,
};

export const localeHomepagePopularSearchesLocation: EntityLocale = {
  id: `homepage.popular_searches.location`,
  defaultMessage: `Location`,
};

export const localeHomepagePopularSearchesKeyword: EntityLocale = {
  id: `homepage.popular_searches.keyword`,
  defaultMessage: `Keyword`,
};

export const localeTextPopularSearchesOnGlints: EntityLocale = {
  id: 'text-popular-searches-on-glints',
  defaultMessage: 'Popular Searches on Glints',
};

/**
 * YellowBanner.tsx
 */
export const localeTextFastEasyUpdated: EntityLocale = {
  id: 'text-fast-easy-updated',
  defaultMessage: 'Fast, easy & updated',
};

export const localeTextFollowUpWithHr: EntityLocale = {
  id: 'text-follow-up-with-hr',
  defaultMessage: 'Follow up with HR directly on your phone',
};

export const localeTextJustachataway: EntityLocale = {
  id: 'text-justachataway',
  defaultMessage: 'Just a chat away from your dream job',
};
