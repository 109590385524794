import { ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const Form = styled.form`
  padding-top: 6px;
  padding-bottom: 64px;

  @media screen and (max-width: 991px) {
    padding-top: 16px;
    padding-bottom: 40px;
  }

  @media screen and (max-width: ${ScreenSize.tablet}px) {
    padding-bottom: 32px;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 54px;
  margin-bottom: 16px;
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  column-gap: 16px;

  @media screen and (max-width: ${ScreenSize.tablet}px) {
    flex-direction: column;
    row-gap: 8px;

    > button {
      margin-top: 8px;
    }
  }
`;

export const FieldWrapper = styled.div`
  flex: 1;
`;

export const LocationFieldWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  column-gap: 4px;

  > div {
    width: 100%;
  }
`;
