import {
  ApolloClient,
  ApolloError,
  FetchPolicy,
  useQuery,
} from '@apollo/client';
import { isEmpty } from 'lodash';

import { tryQuery } from 'src/common/graphql/graphqlHelper';
import isServer from 'src/common/isServer';
import {
  getHierarchicalJobCategoryIncludingSlugById,
  GetJobCategoriesResponse,
  getJobCategoryById,
  JobCategory,
} from 'src/modules/Profile/graphql/jobCategoriesAndTitles';

export interface HierarchicalJobCategoriesResult {
  jobCategories: JobCategory[] | undefined;
  loading: boolean;
  error?: ApolloError;
}

export const useGetHierarchicalJobCategoriesByIds = (
  ids?: string[],
  skip?: boolean
): HierarchicalJobCategoriesResult => {
  const { data, loading, error } = useQuery<{
    getHierarchicalJobCategoriesByIds: JobCategory[];
  }>(getHierarchicalJobCategoryIncludingSlugById, {
    variables: {
      ids: ids,
    },
    fetchPolicy: (isServer ? 'cache-first' : 'network-only') as FetchPolicy,
    errorPolicy: 'all',
    skip: skip || isEmpty(ids),
  });

  return {
    jobCategories: data?.getHierarchicalJobCategoriesByIds,
    loading,
    error,
  };
};

export const tryGetJobCategoriesByIds = async (args: {
  apolloClient: ApolloClient<any>;
  ids?: string[];
}): Promise<{ jobCategories?: JobCategory[]; error?: any }> => {
  const { response, error } = await tryQuery<{
    getHierarchicalJobCategoriesByIds: JobCategory[];
  }>({
    apolloClient: args.apolloClient,
    query: getHierarchicalJobCategoryIncludingSlugById,
    variables: {
      ids: args.ids ?? [],
    },
  });

  return {
    error,
    jobCategories: response?.getHierarchicalJobCategoriesByIds,
  };
};

export const useGetJobCategory = (
  id?: string,
  skip?: boolean
): { jobCategory?: JobCategory; loading: boolean; error?: any } => {
  const { data, loading, error } = useQuery<GetJobCategoriesResponse>(
    getJobCategoryById,
    {
      variables: {
        id: id,
      },
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
      skip: skip || !id,
    }
  );
  return {
    jobCategory: data?.hierarchicalJobCategory,
    loading,
    error,
  };
};
