import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import GlintsPicture from 'src/components/GlintsPicture';
import { AssetType } from 'src/components/GlintsPicture/interfaces';
import { EBreakpoints } from 'src/media';
import AriesButtonBlue from 'src/modules/Landing/LandingPageIndonesia/Components/AriesButtonBlue';

import {
  localeTextDownloadTheGlintsAppNow,
  localeTextFastEasyUpdated,
  localeTextFollowUpWithHr,
  localeTextJustachataway,
  localeTextScanTheQrCodeToDownload,
} from '../../LandingPageLocaleKeys';
import * as S from './YellowBanner.sc';
import { MediaStyle, QRCodeSection } from './YellowBanner.sc';

interface Props {
  language: string;
}

const YellowBanner = (props: Props) => {
  return (
    <S.BannerWrapper>
      <MediaStyle lessThan={EBreakpoints.tablet}>
        <Mobile {...props} />
      </MediaStyle>
      <MediaStyle at={EBreakpoints.tablet}>
        <Tablet {...props} />
      </MediaStyle>
      <MediaStyle greaterThanOrEqual={EBreakpoints.desktopS}>
        <Desktop {...props} />
      </MediaStyle>
    </S.BannerWrapper>
  );
};

const Desktop = (props: Props) => {
  const intl = useIntl();
  const scanToDownload = props.language.includes('id')
    ? 'scanToDownload_ID.webp'
    : 'scanToDownload_EN.webp';

  const desktopPhone = props.language.includes('id')
    ? 'phoneDesktop_ID.webp'
    : 'phoneDesktop_EN.webp';

  return (
    <S.RowContent>
      <div>
        <S.Title>
          <FormattedMessage {...localeTextFastEasyUpdated} />
        </S.Title>
        <S.SubTitle>
          <FormattedMessage {...localeTextFollowUpWithHr} />
        </S.SubTitle>
        <S.JustChatText>
          <FormattedMessage {...localeTextJustachataway} />
        </S.JustChatText>
        <QRCodeSection>
          <S.QRWrapper>
            <GlintsPicture
              name={'yellowBanner/qrCode.webp'}
              assetType={AssetType.dstHomePage}
              width={'100%'}
              breakpoints={[250, 500]}
              eager={true}
              alt={intl.formatMessage(localeTextScanTheQrCodeToDownload)}
            />
          </S.QRWrapper>
          <S.GlintsScanToDownloadWrapper>
            <GlintsPicture
              name={`yellowBanner/${scanToDownload}`}
              assetType={AssetType.dstHomePage}
              width={'100%'}
              breakpoints={[250, 500]}
              eager={true}
              alt={intl.formatMessage(localeTextScanTheQrCodeToDownload)}
            />
          </S.GlintsScanToDownloadWrapper>
        </QRCodeSection>
      </div>
      <S.GlintsDesktopPhoneWrapper>
        <GlintsPicture
          name={`yellowBanner/${desktopPhone}`}
          assetType={AssetType.dstHomePage}
          height={'100%'}
          eager={true}
          alt={intl.formatMessage(localeTextScanTheQrCodeToDownload)}
        />
      </S.GlintsDesktopPhoneWrapper>
    </S.RowContent>
  );
};

const Tablet = (props: Props) => {
  const intl = useIntl();
  const scanToDownload = props.language.includes('id')
    ? 'scanToDownload_ID.webp'
    : 'scanToDownload_EN.webp';

  return (
    <S.RowContent>
      <div>
        <S.Title>
          <FormattedMessage {...localeTextFastEasyUpdated} />
        </S.Title>
        <S.SubTitle>
          <FormattedMessage {...localeTextFollowUpWithHr} />
        </S.SubTitle>
        <S.JustChatText>
          <FormattedMessage {...localeTextJustachataway} />
        </S.JustChatText>
        <QRCodeSection>
          <S.QRWrapper>
            <GlintsPicture
              name={'yellowBanner/qrCode.webp'}
              assetType={AssetType.dstHomePage}
              width={'100%'}
              breakpoints={[250, 500]}
              eager={true}
              alt={intl.formatMessage(localeTextScanTheQrCodeToDownload)}
            />
          </S.QRWrapper>
          <S.GlintsScanToDownloadWrapper>
            <GlintsPicture
              name={`yellowBanner/${scanToDownload}`}
              assetType={AssetType.dstHomePage}
              width={'100%'}
              breakpoints={[250, 500]}
              eager={true}
              alt={intl.formatMessage(localeTextScanTheQrCodeToDownload)}
            />
          </S.GlintsScanToDownloadWrapper>
        </QRCodeSection>
      </div>
    </S.RowContent>
  );
};

const Mobile = (props: Props) => {
  const intl = useIntl();
  const phone = props.language.includes('id')
    ? 'phoneMobile_ID.webp'
    : 'phoneMobile_EN.webp';

  return (
    <S.RowContent>
      <S.Title>
        <FormattedMessage {...localeTextFastEasyUpdated} />
      </S.Title>
      <S.SubTitle>
        <FormattedMessage {...localeTextFollowUpWithHr} />
      </S.SubTitle>
      <S.JustChatText>
        <FormattedMessage {...localeTextJustachataway} />
      </S.JustChatText>
      <AriesButtonBlue
        text={intl.formatMessage(localeTextDownloadTheGlintsAppNow)}
        link={'https://dynamic-link.glints.com/page/hpyellowbannermweb'}
      />
      <S.GlintsMobilePhoneWrapper>
        <GlintsPicture
          name={`yellowBanner/${phone}`}
          assetType={AssetType.dstHomePage}
          width={'100%'}
          aspectRatio={958 / 1198}
          breakpoints={[20, 420]}
          eager={true}
          alt={intl.formatMessage(localeTextScanTheQrCodeToDownload)}
        />
      </S.GlintsMobilePhoneWrapper>
    </S.RowContent>
  );
};

export default YellowBanner;
