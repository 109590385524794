import { Typography } from 'glints-aries/lib/@next';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  color: #2d2d2d;

  @media screen and (max-width: 991px) {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;

    .job-item:nth-child(4),
    .job-item:nth-child(8),
    .job-item:nth-child(12) {
      order: -1;
    }
  }

  @media screen and (max-width: 640px) {
    gap: 8px;

    .job-item {
      height: 78px;
      flex-direction: column;
    }
  }
`;

export const JobCard = styled.div<{ isHighlighted: boolean; color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  column-gap: 10px;
  row-gap: 5px;
  padding: 16px 11px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border-left: ${(props) =>
    props.isHighlighted ? `3px solid ${props.color}` : ''};
  box-shadow:
    0 0 5px rgba(71, 71, 71, 0.1),
    0 1px 2px rgba(71, 71, 71, 0.2);

  &:hover {
    box-shadow:
      rgba(0, 0, 0, 0.2) 0 0 0 0,
      rgba(45, 45, 45, 0.16) 0 2px 16px;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    height: 78px;
  }
`;

export const JobText = styled(Typography)<{
  isHighlighted: boolean;
  highlightColor?: string;
}>`
  color: ${({ highlightColor, isHighlighted }) =>
    isHighlighted ? highlightColor : '#2d2d2d'};
  text-align: center;
  word-break: break-word;
`;

export const JobHighlightIcon = styled.img`
  @media screen and (max-width: 640px) {
    width: 16px;
    height: 16px;
  }
`;
