import React from 'react';
import { Typography } from 'glints-aries/lib/@next';
import Link from 'next/link';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { AssetType } from 'src/components/GlintsPicture/interfaces';
import { EBreakpoints, Media } from 'src/media';

import {
  localeTextGlintsIsFastestPlatform,
  localeTextJobRoleSeniorAccountExecutive,
  localeTextMillionPeopleGetHired,
  localeTextSeeHowLandJobs,
  localeTextSkillContentStrategy,
  localeTextSkillMarketingCommunications,
  localeTextTestimonialOne,
  localeTextTestimonialTwo,
  localeTextYears,
} from '../../LandingPageLocaleKeys';
import * as S from './Testimonials.sc';

type Props = {
  href: string | null;
  image: string;
  content: MessageDescriptor;
  name: string;
  age: number;
  jobTitle: MessageDescriptor;
};

const testimonials: Props[] = [
  {
    href: 'https://glints.com/id/lowongan/bangun-karier-sesuai-passion-jadi-penulis/?utm_source=website&utm_medium=testimonial&utm_campaign=talent-stories',
    image: 'user1.webp',
    content: localeTextGlintsIsFastestPlatform,
    name: 'Windya A.',
    age: 24,
    jobTitle: localeTextSkillContentStrategy,
  },
  {
    href: 'https://glints.com/id/lowongan/sukses-berkarier-di-bidang-it/?utm_source=website&utm_medium=testimonial&utm_campaign=talent-stories',
    image: 'user2.webp',
    content: localeTextTestimonialOne,
    name: 'Dimas B Wicaksono',
    age: 26,
    jobTitle: localeTextJobRoleSeniorAccountExecutive,
  },
  {
    href: null,
    image: 'user3.webp',
    content: localeTextTestimonialTwo,
    name: 'Ashalia T. Tasha',
    age: 21,
    jobTitle: localeTextSkillMarketingCommunications,
  },
];

const Testimonials = () => {
  const intl = useIntl();
  return (
    <S.TestimonialsContent>
      <S.Title>
        <FormattedMessage {...localeTextMillionPeopleGetHired} />
      </S.Title>
      <S.SubTitle>
        <FormattedMessage {...localeTextSeeHowLandJobs} />
      </S.SubTitle>
      <S.ColumnSection>
        {testimonials.map((testimonial, index) => {
          const subProps: SubComponentProps = {
            image: testimonial.image,
            name: testimonial.name,
            age: intl.formatMessage(localeTextYears, {
              age: testimonial.age,
            }),
            content: intl.formatMessage(testimonial.content),
            jobTitle: intl.formatMessage(testimonial.jobTitle),
          };

          const content = (
            <div key={`${testimonial.name}_${index}`}>
              <Media lessThan={EBreakpoints.mobileL}>
                <Mobile {...subProps} />
              </Media>
              <Media at={EBreakpoints.mobileL}>
                <Tablet {...subProps} />
              </Media>
              <Media at={EBreakpoints.tablet}>
                <Tablet {...subProps} />
              </Media>
              <Media greaterThan={EBreakpoints.tablet}>
                <Desktop {...subProps} />
              </Media>
            </div>
          );
          return testimonial.href ? (
            <Link href={testimonial.href} key={`${testimonial.name}_${index}`}>
              <a target="_blank">{content}</a>
            </Link>
          ) : (
            content
          );
        })}
      </S.ColumnSection>
    </S.TestimonialsContent>
  );
};

type SubComponentProps = {
  image: string;
  content: string;
  age: string;
  name: string;
  jobTitle: string;
};

const Desktop = (props: SubComponentProps) => {
  const imageHeight = 120;
  return (
    <S.WrapperDesktop>
      <S.UserLogoDivDesktop imageHeight={imageHeight}>
        <S.UserLogoImage
          name={`testimonials/${props.image}`}
          assetType={AssetType.dstHomePage}
          height={imageHeight}
          width={imageHeight}
          alt="Testimonial user"
          breakpoints={[100, 200, 300]}
        />
      </S.UserLogoDivDesktop>
      <S.UserContentDesktop>
        <div style={{ marginTop: imageHeight / 2 }}>
          <Typography variant="body1">{props.content}</Typography>
        </div>
        <S.UserContentBottomDesktop>
          <Typography variant="overline" color="#666666">
            {props.name}, {props.age} <br />
            {props.jobTitle}
          </Typography>
        </S.UserContentBottomDesktop>
      </S.UserContentDesktop>
    </S.WrapperDesktop>
  );
};

const Mobile = (props: SubComponentProps) => {
  return (
    <S.WrapperMobile>
      <S.UserLogoImageDivMobile>
        <S.UserLogoImage
          name={`testimonials/${props.image}`}
          assetType={AssetType.dstHomePage}
          height={60}
          width={60}
          alt="Testimonial user"
          breakpoints={[100, 200, 300]}
        />
      </S.UserLogoImageDivMobile>
      <S.CardContentMobile>
        <Typography variant="body1">{props.content}</Typography>
        <S.CardBottomMobile>
          <Typography variant="overline">
            {props.name}, {props.age}, {props.jobTitle}
          </Typography>
        </S.CardBottomMobile>
      </S.CardContentMobile>
    </S.WrapperMobile>
  );
};

const Tablet = (props: SubComponentProps) => {
  const _imageSize = 100;

  return (
    <S.WrapperTablet imageHeight={_imageSize}>
      <S.UserLogoDivTablet imageHeight={_imageSize}>
        <S.UserLogoImage
          name={`testimonials/${props.image}`}
          assetType={AssetType.dstHomePage}
          height={_imageSize}
          width={_imageSize}
          alt="Testimonial user"
          breakpoints={[100, 200, 300]}
        />
      </S.UserLogoDivTablet>
      <S.UserContentTablet imageHeight={_imageSize}>
        <S.UserContentTextTablet>
          <S.UserContentTextTypographyTablet variant="body1">
            {props.content}
          </S.UserContentTextTypographyTablet>
        </S.UserContentTextTablet>
        <S.UserContentBottomTablet>
          <Typography
            variant="overline"
            color="#666"
            style={{ letterSpacing: 1.5 }}
          >
            {props.name}, {props.age}, {props.jobTitle}
          </Typography>
        </S.UserContentBottomTablet>
      </S.UserContentTablet>
    </S.WrapperTablet>
  );
};

export default Testimonials;
