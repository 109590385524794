import React from 'react';
import Link from 'next/link';
import { FormattedMessage, useIntl } from 'react-intl';

import GlintsPicture from 'src/components/GlintsPicture';
import { AssetType } from 'src/components/GlintsPicture/interfaces';
import { EBreakpoints } from 'src/media';

import {
  localeInteractiveApplyJobsOnApp,
  localeInteractiveChatWithHrdOnApp,
  localeInteractiveGoToApp,
  localeInteractiveViewRecommendationsOnApp,
  localeTextAfraidForgettingApplyJobs,
  localetextChatWithHrdAnytimeAnywhere,
  localeTextDiscoverYourDreamJob,
  localeTextDownloadTheGlintsAppNow,
  localeTextGetJobRecommendationsPreferences,
  localeTextLetJobsComeYou,
  localeTextNeverMissHdrMessage,
  localeTextRemindersApplySavedJobs,
  localeTextScanTheQrCodeToDownload,
  localeTextSearchApplyGetHired,
  localeTextUnlockFullPotential,
} from '../../LandingPageLocaleKeys';
import * as S from './BlueBanner.sc';

interface Props {
  language: string;
}

const BlueBanner = (props: Props) => {
  return (
    <S.BannerBackground>
      <S.MediaStyle lessThan={EBreakpoints.mobileL}>
        <Mobile {...props} />
      </S.MediaStyle>
      <S.MediaStyle greaterThanOrEqual={EBreakpoints.mobileL}>
        <Desktop {...props} />
      </S.MediaStyle>
    </S.BannerBackground>
  );
};

const Desktop = (props: Props) => {
  const intl = useIntl();
  let phone1 = 'phoneDesktop1_EN.webp';
  let phone2 = 'phoneDesktop2_EN.webp';
  let phone3 = 'phoneDesktop3_EN.webp';
  let scanTo = 'scanToDownload_EN.webp';
  if (props.language.includes('id')) {
    phone1 = 'phoneDesktop1_ID.webp';
    phone2 = 'phoneDesktop2_ID.webp';
    phone3 = 'phoneDesktop3_ID.webp';
    scanTo = 'scanToDownload_ID.webp';
  }
  const altPictureForTheQRCodeToDownload = intl.formatMessage(
    localeTextScanTheQrCodeToDownload
  );

  return (
    <S.BannerContent>
      <S.Title centered={true}>
        <FormattedMessage {...localeTextSearchApplyGetHired} />
      </S.Title>
      <S.SubTitle centered={true}>
        <FormattedMessage {...localeTextUnlockFullPotential} />
      </S.SubTitle>
      <S.DesktopPhoneRowContent>
        <S.GlintsDesktopPhoneWrapper>
          <GlintsPicture
            name={`blueBanner/${phone1}`}
            assetType={AssetType.dstHomePage}
            width={'100%'}
            breakpoints={[250, 500]}
            eager={true}
            alt={altPictureForTheQRCodeToDownload}
          />
        </S.GlintsDesktopPhoneWrapper>
        <S.GlintsDesktopPhoneWrapper>
          <GlintsPicture
            name={`blueBanner/${phone2}`}
            assetType={AssetType.dstHomePage}
            width={'100%'}
            breakpoints={[250, 500]}
            eager={true}
            alt={altPictureForTheQRCodeToDownload}
          />
        </S.GlintsDesktopPhoneWrapper>
        <S.GlintsDesktopPhoneWrapper>
          <GlintsPicture
            name={`blueBanner/${phone3}`}
            assetType={AssetType.dstHomePage}
            width={'100%'}
            breakpoints={[250, 500]}
            eager={true}
            alt={altPictureForTheQRCodeToDownload}
          />
        </S.GlintsDesktopPhoneWrapper>
      </S.DesktopPhoneRowContent>
      <S.DesktopBottomRowContent>
        <div>
          <S.Title centered={false}>
            <FormattedMessage {...localeTextDownloadTheGlintsAppNow} />
          </S.Title>
          <S.SubTitle centered={false}>
            <FormattedMessage {...localeTextDiscoverYourDreamJob} />
          </S.SubTitle>
        </div>
        <div>
          <S.QRWrapper>
            <GlintsPicture
              name={'blueBanner/qrCode.webp'}
              assetType={AssetType.dstHomePage}
              width={'100%'}
              breakpoints={[250, 500]}
              eager={true}
              alt={altPictureForTheQRCodeToDownload}
            />
          </S.QRWrapper>
          <S.GlintsScanToDownloadWrapper>
            <GlintsPicture
              name={`blueBanner/${scanTo}`}
              assetType={AssetType.dstHomePage}
              width={'100%'}
              breakpoints={[250, 500]}
              eager={true}
              alt={altPictureForTheQRCodeToDownload}
            />
          </S.GlintsScanToDownloadWrapper>
        </div>
      </S.DesktopBottomRowContent>
    </S.BannerContent>
  );
};

const Mobile = (props: Props) => {
  const intl = useIntl();
  let phone1 = 'phoneDesktop1_EN.webp';
  let phone2 = 'phoneDesktop2_EN.webp';
  let phone3 = 'phoneDesktop3_EN.webp';

  if (props.language.includes('id')) {
    phone1 = 'phoneDesktop1_ID.webp';
    phone2 = 'phoneDesktop2_ID.webp';
    phone3 = 'phoneDesktop3_ID.webp';
  }
  const altPictureForTheQRCodeToDownload = intl.formatMessage(
    localeTextScanTheQrCodeToDownload
  );
  return (
    <S.BannerContent>
      <MobileItem
        image={phone1}
        title={intl.formatMessage(localeTextLetJobsComeYou)}
        subtitle={intl.formatMessage(
          localeTextGetJobRecommendationsPreferences
        )}
        actionText={intl.formatMessage(
          localeInteractiveViewRecommendationsOnApp
        )}
        alt={altPictureForTheQRCodeToDownload}
      />
      <MobileItem
        image={phone2}
        title={intl.formatMessage(localetextChatWithHrdAnytimeAnywhere)}
        subtitle={intl.formatMessage(localeTextNeverMissHdrMessage)}
        actionText={intl.formatMessage(localeInteractiveChatWithHrdOnApp)}
        alt={altPictureForTheQRCodeToDownload}
      />
      <MobileItem
        image={phone3}
        title={intl.formatMessage(localeTextRemindersApplySavedJobs)}
        subtitle={intl.formatMessage(localeTextAfraidForgettingApplyJobs)}
        actionText={intl.formatMessage(localeInteractiveApplyJobsOnApp)}
        alt={altPictureForTheQRCodeToDownload}
      />
      <MobileItem
        image={phone1}
        title={intl.formatMessage(localeTextSearchApplyGetHired)}
        subtitle={intl.formatMessage(localeTextDiscoverYourDreamJob)}
        actionText={intl.formatMessage(localeInteractiveGoToApp)}
        alt={altPictureForTheQRCodeToDownload}
      />
    </S.BannerContent>
  );
};

const MobileItem = (props: {
  image: string;
  title: string;
  subtitle: string;
  actionText: string;
  alt: string;
}) => {
  return (
    <S.MobileItemWrapper>
      <S.GlintsMobilePhoneWrapper>
        <GlintsPicture
          name={`blueBanner/${props.image}`}
          assetType={AssetType.dstHomePage}
          width={'100%'}
          breakpoints={[250, 500]}
          eager={true}
          alt={props.alt}
        />
      </S.GlintsMobilePhoneWrapper>
      <S.TitleMobile>{props.title}</S.TitleMobile>
      <S.SubTitleMobile>{props.subtitle}</S.SubTitleMobile>
      <Link href={'https://dynamic-link.glints.com/page/hpdeepbluebannermweb'}>
        <S.MobileButton>{props.actionText}</S.MobileButton>
      </Link>
    </S.MobileItemWrapper>
  );
};

export default BlueBanner;
