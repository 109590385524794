import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import GlintsPicture from 'src/components/GlintsPicture';
import { AssetType } from 'src/components/GlintsPicture/interfaces';
import { EBreakpoints } from 'src/media';

import { localeTextBigCitiesLocalArea } from '../../LandingPageLocaleKeys';
import * as S from './MapSection.sc';
import { MediaStyle } from './MapSection.sc';

type Props = {
  href: string;
  locationName: string;
};

const locations: Props[] = [
  {
    locationName: 'Jakarta',
    href: '/id/job-location/indonesia/dki-jakarta',
  },
  {
    locationName: 'Tangerang',
    href: '/id/job-location/indonesia/banten/tangerang',
  },
  {
    locationName: 'Semarang',
    href: '/id/job-location/indonesia/jawa-tengah/semarang',
  },
  {
    locationName: 'Depok',
    href: '/id/job-location/indonesia/jawa-barat/depok',
  },
  {
    locationName: 'Bandung',
    href: '/id/job-location/indonesia/jawa-barat/bandung',
  },
  {
    locationName: 'Surabaya',
    href: '/id/job-location/indonesia/jawa-timur/surabaya',
  },
  {
    locationName: 'Bogor',
    href: '/id/job-location/indonesia/jawa-barat/bogor',
  },
  {
    locationName: 'Medan',
    href: '/id/job-location/indonesia/sumatra-utara/medan',
  },
  {
    locationName: 'Yogyakarta',
    href: '/id/job-location/indonesia/di-yogyakarta-jogja/yogyakarta-jogja',
  },
  {
    locationName: 'Bekasi',
    href: '/id/job-location/indonesia/jawa-barat/bekasi',
  },
  {
    locationName: 'Malang',
    href: '/id/job-location/indonesia/jawa-timur/malang',
  },
  {
    locationName: 'Makassar',
    href: '/id/job-location/indonesia/sulawesi-selatan/makassar',
  },
];

const MapSection = () => {
  return (
    <S.BannerBackground>
      <S.BannerContent>
        <S.Title>
          <FormattedMessage {...localeTextBigCitiesLocalArea} />
        </S.Title>
        <MediaStyle lessThan={EBreakpoints.mobileL}>
          <Mobile />
        </MediaStyle>
        <MediaStyle greaterThanOrEqual={EBreakpoints.mobileL}>
          <Desktop />
        </MediaStyle>
        <GeoMap />
      </S.BannerContent>
    </S.BannerBackground>
  );
};

const Desktop = () => {
  const intl = useIntl();

  return (
    <S.MapWrapper>
      <GlintsPicture
        name={'mapSection/map.webp'}
        assetType={AssetType.dstHomePage}
        width={'100%'}
        breakpoints={[512, 1024, 1536]}
        alt={intl.formatMessage(localeTextBigCitiesLocalArea)}
      />
    </S.MapWrapper>
  );
};

const Mobile = () => {
  const intl = useIntl();
  return (
    <S.MapWrapper>
      <GlintsPicture
        name={'mapSection/mapMobile.webp'}
        assetType={AssetType.dstHomePage}
        width={'100%'}
        breakpoints={[512, 1024, 1536]}
        alt={intl.formatMessage(localeTextBigCitiesLocalArea)}
      />
    </S.MapWrapper>
  );
};

const GeoMap = () => {
  return (
    <S.GeoMapWrapper>
      {locations.map((loc, idx) => (
        <S.StyledLink
          key={`${loc.href}_${idx}`}
          href={loc.href}
          rel="noreferrer"
          target="_blank"
        >
          {loc.locationName}
        </S.StyledLink>
      ))}
    </S.GeoMapWrapper>
  );
};

export default MapSection;
