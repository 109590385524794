import { ScreenSize } from 'glints-aries';
import styled from 'styled-components';

import { LANDING_ID_MAX_WIDTH } from 'src/modules/Landing/LandingPageIndonesia/constants';

export const PopularSearchesWrapper = styled.div`
  background: white;
`;

export const PopularSearchesContent = styled.div`
  max-width: ${LANDING_ID_MAX_WIDTH}px;
  margin: 0 auto;
  @media (max-width: ${ScreenSize.desktopS}px) {
    padding: 0 16px;
  }
`;

export const PopularSearchTabWrapper = styled.div`
  max-width: ${LANDING_ID_MAX_WIDTH}px;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
`;

export const TextBlock = styled.span`
  text-decoration: underline;
  margin-right: 13px;
  margin-bottom: 16px;
  line-height: 140%;
`;
