import { MessageDescriptor } from 'react-intl';

import {
  localeActivelyHiring,
  localeInteractiveFreshGraduates,
  localeInteractiveRemoteJobs,
  localeJobCategory1,
  localeJobCategory2,
  localeJobCategory8,
  localeJobCategory49,
  localeJobCategory51,
  localeJobCategory53,
  localeProjectBased,
  localeTextJobRoleBumn,
  localeTextJobRoleFreelanceWriter,
  localeTextJobRoleGuru,
  localeTextJobRoleLulusanSma,
  localeTextJobRolesupirPribadi,
  localeTextJobRoleWeddingOrganizer,
  localeTextJobRoleWfh,
  localeTextL1AccountingFinance,
  localeTextL1AdministrativeHumanResources,
  localeTextL1BusinessDevelopmentSales,
  localeTextL1ComputerInformationTechnology,
  localeTextL1CustomerService,
  localeTextL1Design,
  localeTextL1Finance,
  localeTextL1Healthcare,
  localeTextL1Marketing,
  localeTextL1MediaCommunications,
  localeTextL1Operations,
  localeTextL1SupplyChainLogistics,
  localeTextL2Administrative,
  localeTextL2Consulting,
  localeTextL2HumanResources,
  localeTextL2Marketing,
  localeTextL2ProductManagement,
  localeTextL3Accountant,
  localeTextL3AccountManager,
  localeTextL3AndroidDeveloper,
  localeTextL3BackendDeveloper,
  localeTextL3CopywriterCopyeditor,
  localeTextL3DataEntry,
  localeTextL3DigitalMarketingManager,
  localeTextL3FilmVideoEditor,
  localeTextL3FrontendDeveloper,
  localeTextL3GraphicBrandDesigner,
  localeTextL3Illustrator,
  localeTextL3Model,
  localeTextL3OfficeManager,
  localeTextL3Recruiter,
  localeTextL3SalesExecutive,
  localeTextL3SalesManager,
  localeTextL3UiUxDesigner,
  localeTextL3VirtualAssistant,
  localeTextL3Writer,
} from '../LandingPageLocaleKeys';
import { Item } from './Components/PopularSearches';

export const LANDING_ID_MAX_WIDTH = 991;

export const POPULAR_JOB_CATEGORIES: {
  text: MessageDescriptor;
  link: string;
  isHighlighted?: boolean;
  highlightColor?: string;
  highlightIcon?: string;
}[] = [
  {
    text: localeTextL1AdministrativeHumanResources,
    link: '/id/job-category/administrative-human-resources',
  },
  {
    text: localeTextL1Marketing,
    link: '/id/job-category/marketing',
  },
  {
    text: localeTextL1Operations,
    link: '/id/job-category/operations',
  },
  {
    text: localeActivelyHiring,
    isHighlighted: true,
    highlightColor: 'rgba(236, 39, 40, 1)',
    highlightIcon: '/images/homepage/icons/active.svg',
    link: '/id/opportunities/jobs/explore?country=ID&locationName=All+Cities%2FProvinces&lastUpdated=PAST_24_HOURS',
  },
  {
    text: localeTextL1SupplyChainLogistics,
    link: '/id/job-category/supply-chain-logistics',
  },
  {
    text: localeTextL1BusinessDevelopmentSales,
    link: '/id/job-category/business-development-sales',
  },
  {
    text: localeTextL1AccountingFinance,
    link: '/id/job-category/accounting-finance',
  },
  {
    text: localeInteractiveRemoteJobs,
    isHighlighted: true,
    highlightIcon: '/images/homepage/icons/remote.svg',
    highlightColor: 'rgba(206, 192, 1, 1)',
    link: '/id/find-jobs/loker-remote',
  },
  {
    text: localeTextL1Design,
    link: '/id/job-category/design',
  },
  {
    text: localeTextL1MediaCommunications,
    link: '/id/job-category/media-communications',
  },
  {
    text: localeTextL1ComputerInformationTechnology,
    link: '/id/job-category/computer-information-technology',
  },
  {
    text: localeInteractiveFreshGraduates,
    highlightIcon: '/images/homepage/icons/fresh.svg',
    isHighlighted: true,
    highlightColor: 'rgba(11, 174, 236, 1)',
    link: '/id/find-jobs/loker-fresh-graduate',
  },
];

export const POPULAR_SEARCH_KEYWORDS: Item[] = [
  {
    textOnlineJobs: localeProjectBased,
    url: '/id/find-jobs/loker-freelance-online',
  },
  {
    textOnlineJobs: localeTextJobRoleWfh,
    url: '/id/find-jobs/loker-work-from-home-online',
  },
  {
    textJobs: localeInteractiveFreshGraduates,
    url: '/id/find-jobs/loker-fresh-graduate',
  },
  {
    text: localeInteractiveRemoteJobs,
    url: '/id/find-jobs/loker-remote',
  },
  {
    textJobs: localeTextJobRoleBumn,
    url: '/id/find-jobs/loker-bumn',
  },
  {
    text: localeTextJobRolesupirPribadi,
    url: '/id/find-jobs/loker-supir-pribadi',
  },
  {
    textJobs: localeTextL3VirtualAssistant,
    url: '/id/find-jobs/loker-virtual-assistant',
  },
  {
    textJobs: localeTextL3CopywriterCopyeditor,
    url: '/id/find-jobs/loker-copywriter',
  },
  {
    textOnlineJobs: localeTextJobRoleGuru,
    url: '/id/find-jobs/loker-guru-online',
  },
  {
    textJobs: localeTextL3Illustrator,
    url: '/id/find-jobs/loker-illustrator',
  },
  {
    textJobs: localeTextJobRoleWeddingOrganizer,
    url: '/id/find-jobs/loker-wedding-organizer',
  },
  {
    textJobs: localeTextL3DataEntry,
    url: '/id/find-jobs/loker-data-entry',
  },
  {
    textJobs: localeTextJobRoleLulusanSma,
    url: '/id/find-jobs/loker-lulusan-sma',
  },
  {
    textJobs: localeTextJobRoleFreelanceWriter,
    url: '/id/find-jobs/loker-freelance-writer',
  },
  {
    textJobs: localeTextL3Model,
    url: '/id/find-jobs/loker-model',
  },
];

export const POPULAR_SEARCH_CATEGORIES: Item[] = [
  {
    textJobs: localeTextL2HumanResources,
    url: '/id/job-category/administrative-human-resources',
  },
  {
    textJobs: localeTextL2Administrative,
    url: '/id/job-category/administrative-human-resources',
  },
  {
    textJobs: localeTextL1SupplyChainLogistics,
    url: '/id/job-category/supply-chain-logistics',
  },
  {
    textJobs: localeJobCategory49,
    url: '/id/job-category/hotel-restaurant-travel',
  },
  {
    textJobs: localeTextL2Marketing,
    url: '/id/job-category/marketing',
  },
  {
    textJobs: localeJobCategory53,
    url: '/id/job-category/legal-consulting-translation',
  },
  {
    textJobs: localeTextL1Finance,
    url: '/id/job-category/accounting-finance',
  },
  {
    textJobs: localeJobCategory51,
    url: '/id/job-category/legal-consulting-translation',
  },
  {
    textJobs: localeTextL1Healthcare,
    url: '/id/job-category/healthcare',
  },
  {
    textJobs: localeTextL2Consulting,
    url: '/id/job-category/legal-consulting-translation',
  },
  {
    textJobs: localeJobCategory1,
    url: '/id/job-category/computer-information-technology',
  },
  {
    textJobs: localeTextL1Design,
    url: '/id/job-category/design',
  },
  {
    textJobs: localeTextL2ProductManagement,
    url: '/id/job-category/product-management-project-management',
  },
  {
    textJobs: localeJobCategory2,
    url: '/id/job-category/computer-information-technology',
  },
  {
    textJobs: localeJobCategory8,
    url: '/id/job-category/computer-information-technology',
  },
];

export const POPULAR_SEARCH_ROLES: Item[] = [
  {
    textJobs: localeTextL3Writer,
    url: '/id/job-category/arts-creative/writing/writer',
  },
  {
    textJobs: localeTextL3Recruiter,
    url: '/id/job-category/administrative-human-resources/human-resources/recruiter',
  },
  {
    textJobs: localeTextL1CustomerService,
    url: '/id/job-category/customer-service',
  },
  {
    textJobs: localeTextL3Accountant,
    url: '/id/job-category/accounting-finance/audit-accounting-taxation-service/accountant',
  },
  {
    textJobs: localeTextL3SalesManager,
    url: '/id/job-category/business-development-sales/sales/sales-manager',
  },
  {
    textJobs: localeTextL3SalesExecutive,
    url: '/id/job-category/business-development-sales/sales',
  },
  {
    textJobs: localeTextL3AccountManager,
    url: '/id/job-category/accounting-finance/financial-service/relationship-manager-finance-account-managers',
  },
  {
    textJobs: localeTextL3UiUxDesigner,
    url: '/id/job-category/design/visual-interactive-design/ui-ux-designer',
  },
  {
    textJobs: localeTextL3FilmVideoEditor,
    url: '/id/job-category/arts-creative/film-television-media/film-video-editor',
  },
  {
    textJobs: localeTextL3DigitalMarketingManager,
    url: '/id/job-category/marketing/digital-marketing/digital-marketing-manager',
  },
  {
    textJobs: localeTextL3FrontendDeveloper,
    url: '/id/job-category/computer-information-technology/frontend-development',
  },
  {
    textJobs: localeTextL3BackendDeveloper,
    url: '/id/job-category/computer-information-technology/backend-development/backend-developer',
  },
  {
    textJobs: localeTextL3AndroidDeveloper,
    url: '/id/job-category/computer-information-technology/mobile-development/android-developer',
  },
  {
    textJobs: localeTextL3GraphicBrandDesigner,
    url: '/id/job-category/design/visual-interactive-design/graphic-brand-designer',
  },
  {
    textJobs: localeTextL3OfficeManager,
    url: '/id/job-category/administrative-human-resources/administrative/office-manager',
  },
];

export const POPULAR_SEARCH_LOCATIONS: Item[] = [
  {
    location: 'Jakarta',
    url: '/id/job-location/indonesia/dki-jakarta',
  },
  {
    location: 'Jakarta Selatan',
    url: '/id/job-location/indonesia/dki-jakarta/jakarta-selatan',
  },
  {
    location: 'Jakarta Pusat',
    url: '/id/job-location/indonesia/dki-jakarta/jakarta-pusat',
  },
  {
    location: 'Jakarta Barat',
    url: '/id/job-location/indonesia/dki-jakarta/jakarta-barat',
  },
  {
    location: 'Jakarta Utara',
    url: '/id/job-location/indonesia/dki-jakarta/jakarta-utara',
  },
  {
    location: 'Jakarta Timur',
    url: '/id/job-location/indonesia/dki-jakarta/jakarta-timur',
  },
  {
    location: 'Bandung',
    url: '/id/job-location/indonesia/jawa-barat/bandung',
  },
  {
    location: 'Karawang',
    url: '/id/job-location/indonesia/jawa-barat/kab-karawang',
  },
  {
    location: 'Surabaya',
    url: '/id/job-location/indonesia/jawa-timur/surabaya',
  },
  {
    location: 'Medan',
    url: '/id/job-location/indonesia/sumatra-utara/medan',
  },
  {
    location: 'Palembang',
    url: '/id/job-location/indonesia/sumatera-selatan/palembang',
  },
  {
    location: 'Tangerang',
    url: '/id/job-location/indonesia/banten/tangerang',
  },
  {
    location: 'Bogor',
    url: '/id/job-location/indonesia/jawa-barat/bogor',
  },
  {
    location: 'Pekanbaru',
    url: '/id/job-location/indonesia/riau/pekanbaru',
  },
  {
    location: 'Semarang',
    url: '/id/job-location/indonesia/jawa-tengah/semarang',
  },
  {
    location: 'Batam',
    url: '/id/job-location/indonesia/kepulauan-riau/batam',
  },
  {
    location: 'Makassar',
    url: '/id/job-location/indonesia/sulawesi-selatan/makassar',
  },
  {
    location: 'Sleman',
    url: '/id/job-location/indonesia/di-yogyakarta-jogja/kab-sleman',
  },
  {
    location: 'Malang',
    url: '/id/job-location/indonesia/jawa-timur/malang',
  },
  {
    location: 'Sidoarjo',
    url: '/id/job-location/indonesia/jawa-timur/kab-sidoarjo',
  },
];
