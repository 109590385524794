import { ScreenSize } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import styled from 'styled-components';

import { LANDING_ID_MAX_WIDTH } from 'src/modules/Landing/LandingPageIndonesia/constants';

export const LandingPageBackground = styled.div`
  background: linear-gradient(
    to bottom,
    #caeefb 0%,
    #f8fafc 1% 3%,
    #f8fafc 3% 100%
  );
`;

export const LandingPageContainer = styled.div`
  max-width: ${LANDING_ID_MAX_WIDTH}px;
  margin-right: auto;
  margin-left: auto;
  padding: 24px 0;

  @media (max-width: ${ScreenSize.desktopS}px) {
    padding: 24px;
  }
`;

export const ColumnSection = styled.div`
  display: flex;
  margin-top: 48px;
  flex-direction: row;
  gap: 48px;

  @media (max-width: ${ScreenSize.desktopS}px) {
    flex-direction: column;
    gap: 32px;
  }
`;

export const SectionTitle = styled(Typography).attrs({
  variant: 'subtitle1',
  as: 'h2',
})`
  margin-bottom: 20px;
  font-weight: 600;
`;
