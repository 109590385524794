import { filter, get } from 'lodash';

import {
  JobInterface,
  JobLocation,
  JobLocationBase,
  LocationIdAndLevel,
} from 'src/modules/Opportunity/interface';
import {
  HierarchicalLocation,
  Maybe,
} from 'src/modules/Profile/graphql/hierarchicalLocations';

import { CountryCodes } from '../enums';

const validCountriesForHierarchicalLocation = [
  CountryCodes.ID,
  CountryCodes.VN,
];

export const validCountryForHierarchicalLocation = (
  countryCode: CountryCodes
) => validCountriesForHierarchicalLocation.includes(countryCode);

export const buildSelectedLocationLabel = (location: HierarchicalLocation) =>
  buildCityProvinceLabel(location);

export const buildLocationFromJob = (
  job: JobInterface,
  levels: number[] = [4, 3, 2]
): string => {
  const { city, country, location } = job;
  if (location) {
    const locations = levels.map((level) => {
      const formatted = filter(
        [location, ...location.parents],
        (parent: JobLocationBase) => parent.level === level
      ).map((parent: JobLocationBase) => parent.formattedName);
      return formatted.length > 0 ? formatted[0] : null;
    });
    return locations.filter((value) => value !== null).join(', ');
  }
  return `${get(city, 'name') ? `${city.name},` : ''} ${get(country, 'name')}`;
};

export const buildCityProvinceLabel = (location: HierarchicalLocation) => {
  const level2 = location.parents?.find((parent) => parent.level === 2);
  return [location?.formattedName, level2?.formattedName]
    .filter((item) => item !== undefined)
    .join(', ');
};

/**
 * This is used in WarningModal. The userLocation is at L2 or L3, while jobLocation is at L4.
 * Therefore, we take userLocation and internally compare it with jobLocation's id and parents,
 * excluding L1 (Country Level).
 * The userLocation might be updated to use L4 in the future, so we start comparing from L4.
 *
 * @param location - The location object to compare.
 * @returns LocationIdAndLevel[] - An array of locations from levels [L4, L3, L2].
 */

export const getJobLocationIdsAndLevels = (
  location: JobLocation
): LocationIdAndLevel[] => {
  const result: LocationIdAndLevel[] = [];

  if (location.id && location.level) {
    result.push({ id: location.id, level: location.level });
  }

  if (location.parents) {
    for (const parent of location.parents) {
      if (parent.level === 1) continue;
      result.push({ id: parent.id, level: parent.level });
    }
  }

  return result;
};

export const getIsUserLocationSameWithJobLocation = (
  userLocationId?: string,
  location?: JobLocation
): boolean => {
  if (!location || !userLocationId) return false;
  return getJobLocationIdsAndLevels(location).some(
    (location) => location.id === userLocationId
  );
};

export const getSlugsURLParameter = (
  location: HierarchicalLocation,
  maxLevel?: number
): string | null => {
  const level = maxLevel ?? location.level;
  const locations = [...(location.parents ?? []), location];

  const findParentByLevel = (level: number): HierarchicalLocation | undefined =>
    locations.find((parent) => parent.level === level);

  const slugs: Array<Maybe<string> | undefined> = [];

  for (let i = 1; i <= level; i++) {
    slugs.push(findParentByLevel(i)?.slug);
  }
  return slugs.filter(Boolean).join('/');
};

export const getLocationData = (
  location: HierarchicalLocation
): {
  countryName?: string;
  countryCode?: string;
  countryId?: string;
  provinceName?: string;
  provinceFormattedName?: string;
  provinceId?: string;
  cityName?: string;
  cityId?: string;
} => {
  // Level 1: Country
  const country = getLocationByLevel(location, 1);

  // Level 2: Province
  const province = getLocationByLevel(location, 2);

  // Level 3: City
  const city = getLocationByLevel(location, 3);
  const countryCode = country?.CountryCode;
  return {
    countryName: country?.name,
    countryCode: countryCode ? (countryCode as string) : undefined,
    countryId: country?.id,
    provinceId: province?.id,
    provinceName: province?.name,
    provinceFormattedName: province?.formattedName,
    cityId: city?.id,
    cityName: city?.name,
  };
};

export const getCountry = (location: HierarchicalLocation) =>
  getLocationByLevel(location, 1);

export const getPro = (location: HierarchicalLocation) =>
  getLocationByLevel(location, 1);

const getLocationByLevel = (
  location: HierarchicalLocation,
  level: 1 | 2 | 3 | 4
): HierarchicalLocation | undefined => {
  const locations = [...(location.parents ?? []), location];
  return locations.find((e) => {
    return e.level === level;
  });
};
